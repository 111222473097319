<template>
    <v-container>
      <v-slide-x-transition hide-on-leave>
        <div v-show="!ShowFormCreate">
          <v-card elevation="2" width="500px" class="mx-auto my-8">
            <v-toolbar flat color="cyan lighten-3">
              <v-toolbar-title>Login as Teacher</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="text-center">
              <p class="text-h6 mb-0">You need to create an account</p>
              <v-btn color="cyan lighten-3" class="ma-2" x-large depressed @click="ClickCreateAccount">Create Account</v-btn>
              <v-divider class="mx-11 my-3"></v-divider>
              <p class="subtitle-2 mb-0">or you already have an account?</p>
              <v-btn color="cyan lighten-3" class="ma-2" x-large href="/login">Login</v-btn>
            </v-card-text>     
          </v-card>
        </div>
      </v-slide-x-transition>
      <v-slide-x-transition hide-on-leave>
      <v-layout wrap v-show="ShowFormCreate">
        <v-flex sm12 md6 offset-md3>
          <v-alert dense outlined type="error" v-if="errorMessage != ''">{{ errorMessage }}</v-alert>
          <v-card elevation="4" light tag="section">
            
            <v-card-title>
              <v-layout align-center justify-space-between>
                <h3 class="headline">
                  Create Account - {{ type.toUpperCase() }}
                </h3>
                <v-flex>
                  <!-- <v-img :alt="platformName" class="ml-3" contain height="48px" position="center right" src="https://www.mobygames.com/images/i/12/25/1435075.png"></v-img> -->
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-form ref="form" v-model="valid" @submit.prevent="SendSignUp" lazy-validation>
                <v-row class="mx-5 mt-2">
                  <v-text-field v-model="user.name" outlined :rules="nameRules" label="Full Name" required></v-text-field>
                </v-row>
                <v-row class="mx-5">
                  <v-text-field v-model="user.email" outlined :rules="emailRules"  label="Email"></v-text-field>
                </v-row>
                <v-row class="mx-5 mb-6">
                  <v-select v-model="user.country" outlined :items="listCountries" item-text="name" menu-props="auto" label="Select Countries" hide-details return-object ></v-select>
                </v-row>
                <v-row class="mx-5">
                  <v-text-field v-model="user.password" outlined :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passwordRules.required, passwordRules.min]" :type="showPassword ? 'text' : 'password'" label="Password" counter @click:append="showPassword = !showPassword"></v-text-field>
                </v-row>
                <v-row class="mx-5">
                  <v-text-field v-model="user.confirmPassword" outlined :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passwordRules.required, passwordRules.min]" :type="showConfirmPassword ? 'text' : 'password'" label="Confirm Password" counter @click:append="showConfirmPassword = !showConfirmPassword"></v-text-field>
                </v-row>
                <v-checkbox v-model="checkbox" class="mx-5">
                  <template v-slot:label>
                    <div>
                      You have read and agree to Clase Lee Lee Auto Renewal Terms, <a target="_blank" href="/termsofservices" @click.stop>Terms of Service and Cancellation Policy</a>
                    </div>
                  </template>
                </v-checkbox>
                <v-row class="my-3">
                  <v-btn color="info" x-large type="submit" :disabled="!checkbox || loading" :loading="loading" class="mx-auto">
                    <v-icon left>mdi-lock</v-icon>
                    Sign Up
                  </v-btn>
                </v-row>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <!-- <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions> -->
          </v-card>
        </v-flex>
        <v-flex sm12 md6 offset-md3>
          <v-layout align-center justify-space-between>
            <p class="caption my-3">
              By signing up, I agree to the <a href="/termsandconditions"> Terms of Service and Privacy Policy </a>
            </p>
            <!-- <p class="caption my-3">Powered by <a href="#">Escuela de Lectura</a></p> -->
          </v-layout>
        </v-flex>
      </v-layout>
      </v-slide-x-transition>
    </v-container>
</template>

<script>
import { functions, db, auth } from '../../plugins/firebase'

export default {
  name: 'Signup',
  props:['type'],
  data: () => ({
    user: {
        name: '',
        email: '',
        password: '',
        confirmPassword:'',
        country: '',
        language: ''
      },
    nameRules: [
      v => (v && v.length >= 2) || 'Minimum length is 2 characters',
      v => (v && v.length <= 30) || 'Name must be less than 30 characters'
    ],
    emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    
    passwordRules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 4 || 'Min 4 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    listCountries:[],
    showPassword: false,
    showConfirmPassword: false,
    errorMessage: '',
    typeAlert: '',
    valid: true,
    checkbox: false,
    loading: false,
    ShowFormCreate: false
  }),
  created () {
    this.GetCountries();
    if (this.type == undefined) {
      location.href = '/pricing';
    }
  },
  methods: {
    GetCountries() {
        // let list = await db.collection('schoolsData').doc('RegionSchoolD').get();
        // //console.log(list.data())
        // let ids = Object.keys(list.data().Countries);
        // let countries = [];
        // ids.forEach(element => {
        //     console.log(element)
        //     countries.push({ id: element, name: list.data().Countries[element] })
        // });
        // this.listCountries = countries;
        // console.log(countries)
      const loginClever = functions.httpsCallable('getCountry') // loginClever    --- loginCleverDemo
      loginClever().then((list)=>{
        console.log(list)
        let ids = Object.keys(list.data);
        let countries = [];
        ids.forEach(element => {
            countries.push({ id: element, name: list.data[element] })
        });
        this.listCountries = countries;
        this.user.country = this.listCountries[0]
      }) 
    },
    async SendSignUp () {
      //validamos
      if (!this.$refs.form.validate()) return;
      if (this.user.password != this.user.confirmPassword) {
        this.errorMessage = "Password error, please complete password again"
        setTimeout(() => this.errorMessage = '', 5000);
        this.user.password = '';
        this.user.confirmPassword = '';
        return;
      }
      //userCredential.user.updateEmail(this.email);
  //falta verificar si existe un email con ese usuario
      //creamos
      this.user.type = this.type;
      this.loading = true;
      const createUserIndividual = functions.httpsCallable('registerIndividualTeacher')
      createUserIndividual(this.user).then((respues) => {
        console.log(respues)
        if (Object.prototype.hasOwnProperty.call(respues.data, 'errorInfo')) {
        console.log(respues.data.errorInfo)
          this.errorMessage = respues.data.errorInfo.message;
          setTimeout(() => this.errorMessage = '', 8000);
          this.user.email = '';
          this.loading = false;
          return;
        }
        const sendMail = functions.httpsCallable('sendMail');
        sendMail({name: this.user.name, email: this.user.email, type: this.type }).then((result) => {
            console.log(result);
            }).catch((error) => {
            console.log(error);
            });
        console.log('termino de crear');
  //falta desloguear
        this.$store.dispatch('login', {email: this.user.email, password: this.user.password}).then(() => {
          
          if (this.type =='full') {
            this.Subscribirse(respues.data);
          }else{
            setTimeout(() => location.href = '/login', 3000);
            //location.href = '/login';
          }
          //location.href = '/select-plan';
        }).catch(error => {
          console.log(error);
          if (Object.prototype.hasOwnProperty.call(error, 'message')) {
            this.MessageAlert(error.message, 'error');
          }else
            this.MessageAlert(error, 'error');
        });
      }).catch((error)=> {
        console.log(error)
        this.loading = false;
      });
    },
    async Subscribirse(idUser) {
      try {
        this.loading = true;
        const createStripeCheckoutSubscription = functions.httpsCallable('createStripeCheckoutSubscription')
        const stripe = Stripe('pk_live_51IWTTYBCweBe746jpuhgLSXpjtmMcd0vk0zB2RJLu2yl9HVungx0TAu3WNiVZKrnAuvCOVPPGHwimE3Mt28g8kTB00t99XeYiM');
        await createStripeCheckoutSubscription()
          .then((response) => {
            let date = new Date();
            console.log(date.getTime())
            //let textData = date.toLocaleString('en-US');
            let textData =date.getTime()
            response.data.session.fecha = date.toLocaleString('en-US');
            const sessionId = response.data.session.id;
            var batch = db.batch();
            var step1 = db.collection('Logs').doc('DataLogs').collection('PaymentLogs').doc(idUser);
            batch.set(step1, {[`${[textData]}`]: response.data.session}, { merge: true });
            var step2 = db.collection('Logs').doc('DataLogs').collection('TokensPayment').doc(sessionId);
            batch.set(step2, {userUID: idUser});
            batch.commit().then(() => {
              stripe.redirectToCheckout({ sessionId: sessionId })
              //this.loading = false;
            });
            // db.collection('Logs').doc('DataLogs').collection('PaymentLogs').doc(idUser).set({
            //   [`${[textData]}`]: response.data.session
            // }, { merge: true }).then(()=>{
              
            //   const sessionId = response.data.session.id;
            //   stripe.redirectToCheckout({ sessionId: sessionId })
            // });
            
          })
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    },
    ClickCreateAccount (){
      this.ShowFormCreate = true;
      //auth().signOut()
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
    },
    async existsUser (email) {
      let signInMethods = await auth().fetchSignInMethodsForEmail(email);
      if (signInMethods.length == 0) {
        console.log('No existe!');
        return false;
              
      } else {
        console.log(signInMethods)
        console.log('si existe')
        return true;
      }
    },
  }
}
</script>
<style>
  .c-pointer{
    cursor: pointer;
  }
  .loginClever{
    text-align: center;
    margin: 15px;
    font-size: 1.1em;
  }
  .loginClever a{
    text-decoration: none;
  }
</style>